
$(document).ready(function() {

	$(".spoiler_content").css('display','none');
	
		// add class to right portfolio if not in mobile
		if( navigator.userAgent.match(/Android/i)
		 || navigator.userAgent.match(/webOS/i)
		 || navigator.userAgent.match(/iPhone/i)
		 || navigator.userAgent.match(/iPod/i)
		 || navigator.userAgent.match(/BlackBerry/i)
		 ){
		 var mobile=true;
		}else{
		 var mobile=false;
		}
		
			if(document.getElementById('summ') != null&&!mobile){
				jQuery("#summ").containedStickyScroll({
					unstick: true
				});
			}
		
if(document.getElementById('content')){
if(document.getElementsByClassName("wow")){
    wow = new WOW({
		animateClass: 'animated',
		offset:       -10
	});
	
    wow.init();
}
}

    $("a.windowOpenSmall").on("click", function () {
        try{

            $("body").prepend('<div class="overlay_dark"></div><div id="loading"></div><div id="alert_ring"><div id="window_ring" class="window"></div></div>');

            var href = $(this).attr("href");
            $(".overlay_dark").show(1);
            $("#loading").show(1);

            $.ajax({
                url:href,
                success:function (data) {
                    $("#window_ring").html(data);
                    $("#alert_ring").show(300);
                },
                error:function (data, status) {
                    $(".overlay_dark").hide(1);
                    alert("Произошла ошибка загрузки: " + data + status);
                },
                complete:function () {
                    try {}
                    finally {

                        $("#loading").hide(1);
                    }
                }
            });
        }
        catch(ex)
        {
            alert(ex);
        }

        return false;
    });

    //Закрытие оверлэй окошка заказа звонка
	$('body').on('click', '#overlay_close', function(){
        try{
            $("#alert_ring").hide(300, function(){
                $(".overlay_dark").hide(1);
                $("#window_ring").html(null);
            });
            $("#alert_ring").remove();
            $("#loading").remove();
            $(".overlay_dark").remove();
        }
        catch(ex)
        {
            alert(ex);
        }
        return false;
    });
	
	$('body').on('click', '#overlay_close_wait', function(){
        try{
            $("#wait_form").hide(300, function(){
                $(".overlay").hide(1);
                $("#wait_form").html(null);
            });
            $("#wait_form").remove();
            $("#loading").remove();
            $(".overlay").remove();
        }
        catch(ex)
        {
            alert(ex);
        }
        return false;
    });

    //КЛИК ПО ЗАТЕМНЕННОЙ ОБЛАСТИ  заказа звонка
	$('body').on('click', '.overlay_dark', function(){
        $("#overlay_close").click();
    });

	$('body').on('click', '.overlay', function(){
        $("#overlay_close_wait").click();
    });

    //*************portfolio_filter*********************************//
    $('.reset_filter').on('click',function(){
        $('#filter input').each(function(){
            $(this).attr('checked', false);
            $(this).parent().removeClass('radioChecked');
        });

        filterDivs(); //вызываем при измене input

        return false;
    });

    $(function() {
        $('.filter_head a').bind('click',function(event){
            var $anchor = $(this);
            $('html, body').stop().animate({
                scrollTop: $($anchor.attr('href')).offset().top
            }, 1500,'easeInOutExpo');

            event.preventDefault();
        });
    });

    $('#filter .niceRadio').on('click',function(){
	
		//закомментить код ниже, чтобы вернуть множественный фильтр	
		var chInp=$(this).attr('checked');
		var chSpan=$(this).hasClass('radioChecked');
			
		$('#filter input').each(function(){
            $(this).attr('checked', false);
            $(this).parent().removeClass('radioChecked');
        });
		
		var type=this.tagName;
		
		if(chInp=='checked'||chSpan){
			if(type.toLowerCase()=='input'){
				$(this).attr('checked', true);
				$(this).parent().addClass('radioChecked');
			}else{
				$(this).children('input').attr('checked', true);
				$(this).addClass('radioChecked');		
			}
		}
		//закомментить код выше, чтобы вернуть множественный фильтр	
		
        filterDivs(); //вызываем при измене input

        return false;
    });

    //*************show_next_items*********************************//

    $(".showNext").on("click", function () {

        var countShow=$(this).attr("id").replace("countShow","");

        if(!countShow) countShow=3;

        $("div.hidden:lt("+countShow+")").removeClass("hidden").show(300);
		
        $("div.gal").children(".clear_hidden:lt("+(countShow-1)+")").removeClass("clear_hidden");
        $("div.clients").children(".clear_hidden:lt("+(countShow/2)+")").removeClass("clear_hidden");

        if($("div.hidden").length==0){
            $(".showNext").hide();
        }
        return false;
    });
    //*************calculate_spoiler*********************************//
    $('.spoilerCaption').click(function(){
        if($(this).attr('class')=='spoilerCaption')
        {
            $('span#'.concat($(this).attr('id').toString(),'text')).slideDown("slow");

            $(this).removeClass('spoilerCaption');

            $(this).addClass('spoilerCaptionDown');
        }
        else
        {
            $('span#'.concat($(this).attr('id').toString(),'text')).slideUp("slow");

            $(this).removeClass('spoilerCaptionDown');

            $(this).addClass('spoilerCaption');
        }

        return false;
    });

    //*************vacancy_spoiler*********************************//
    $('.vac_spoilerCaption').click(function(){
        if($(this).attr('class')=='vac_spoilerCaption')
        {
            $('span#'.concat($(this).attr('id').toString(),'def')).slideDown("slow");
            $(this).removeClass('vac_spoilerCaption');
            $(this).addClass('vac_spoilerCaptionDown');
        }
        else
        {
            $('span#'.concat($(this).attr('id').toString(),'def')).slideUp("slow");
            $(this).removeClass('vac_spoilerCaptionDown');
            $(this).addClass('vac_spoilerCaption');
        }
        return false;
    });

    //*************calculator_scripts*********************************//
    recountPrice();

    $(".calc_pr input:checkbox").click(function(){
        recountPrice();
    });

    $(".calc_pr input:radio").click(function(){
        recountPrice();

        if ($(this).attr("type") == "radio") {
            var str = ":checkbox[name='" + $(this).attr("name") + "']";
            $(str).attr("checked", true);
        }
    });

    $(".parentInp").on("click", function () {
            var str = ":radio[name='" + $(this).attr("name") + "']";
            if($(this).attr("checked")){
                $(str).each(function () {
                        $(this).attr("checked", true);
                        return false ;
                    }
                );
            }
            else {
                $(str).each(function () {
                        $(this).attr("checked", false);
                    }
                );
            }
            recountPrice();
        }
    );

    $(".changeGroup").on("click",function(){
        var idGroup=$(this).attr("id").replace('group','');

        $(".changeGroup").each(function(){
            $(this).parent().removeClass('tab_cur');
        });

        if(idGroup==0){
            resetPrice();
            $("#group0").parent().addClass('tab_cur');
        }
        else{
            resetPrice();
            $("#group"+idGroup).parent().addClass('tab_cur');

            var arr=priceGroup;
            for(var i in priceGroup[idGroup]){
                if (!priceGroup[idGroup][i]) continue;
                var item=$(".calc_pr input#pr"+i);
                if(item.attr('type')=='radio'){
                    var str = ":checkbox[name='" + $(item).attr("name") + "']";
                    $(str).attr("checked", true);
                    item.attr("checked","checked");
                }else{
                    item.attr("checked","checked");
                }
            }
        }
        recountPrice();

        return false;
    });

    $("#reset").on("click", function () {
            resetPrice();
            recountPrice();
        }
    );

    $(".hide_no_script").removeClass('hide_no_script');


    /*********СКРЫТЬ САЙТЫ****************************************/
    $(".hidden").each(function(){
        $(this).hide(200);
    });

    //*************portfolio_item_picture*********************************//
    $(".preview_img").on('click',function(){

        var id=$(this).attr('id').replace('photo','');

		$(".item_img").css('position','absolute');
        $(".item_img").hide();
        $(".previews_item").removeClass("previews_item_cur");

        $(this).parent().addClass("previews_item_cur");
		$("#big"+id).css('position','relative');
        $("#big"+id).show(200);
        return false;
    });

	
	   $('.summ_open').click(function(){
        if($(this).attr('class')=='summ_close')
        {
            $('.summ_block').show("slow");

            $(this).removeClass('summ_close');

            $(this).addClass('summ_open');
			
			$(this).attr('title','Свернуть');
        }
        else
        {
            $('.summ_block').hide("slow");

            $(this).removeClass('summ_open');

            $(this).addClass('summ_close');
			
			$(this).attr('title','Развернуть');
        }

        return false;
    });
	
	$(".spoiler_head").click(function(){
		$(this).toggleClass('opened');
		$(this).parents(".spoiler_item").find('.spoiler_content').toggleClass('opened');
		return false;
	});
	
	function getCookie(name) {
	  var matches = document.cookie.match(new RegExp(
		"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
	  ));
	  return matches ? decodeURIComponent(matches[1]) : undefined;
	}
	function setCookie(name, value, options) {
	  options = options || {};

	  var expires = options.expires;

	  if (typeof expires == "number" && expires) {
		var d = new Date();
		d.setTime(d.getTime() + expires * 1000);
		expires = options.expires = d;
	  }
	  if (expires && expires.toUTCString) {
		options.expires = expires.toUTCString();
	  }

	  value = encodeURIComponent(value);

	  var updatedCookie = name + "=" + value;

	  for (var propName in options) {
		updatedCookie += "; " + propName;
		var propValue = options[propName];
		if (propValue !== true) {
		  updatedCookie += "=" + propValue;
		}
	  }

	  document.cookie = updatedCookie;
	}
	$('body').mouseleave(function(){
		return false;
		var cookie=getCookie('viewForm');
		if(cookie==undefined){
			var date = new Date(new Date().getTime() + 60 * 1000 * 60 * 12);
			setCookie('viewForm', 'yes', {expires:date,path:'/'});
			
            $("body").prepend('<div class="overlay"></div><div id="wait_form" class="wait_form"></div>');
			$.ajax({
				url: "/wait/form",
				type: "GET",
				success: function(response) {

					$("#wait_form").html(response);
				},
				error: function(response) {
					alert("На сервере произошла ошибка при обработке Вашего запроса");
				},
				complete:function () {
					try {}
					finally {
					}
				}
			});
		}
		return false;
	});
});

//reset all checkboxes in calculator
function resetPrice()
{
    $(".calc_pr input:checked").each(function () {
            $(this).attr("checked", false);
        }
    );
}
//count finish summ for calculator
function recountPrice()
{
    var sum=0;

    $('.calc_pr input:checked').each(function(){
        sum=sum+parseInt($(this).attr('value'));
    });
    $(".calc_pr option:selected").each(function(){
        sum=sum+parseInt($(this).attr('value'));
    });

    $("#sum_number").html(sum);
   /* $("#sum_number_seo").html(sum/2);*/
    $("#sum_number_seo").html(sum-sum*0.3);
}

//clear elements from hidden class
function clearHidden(){
    $(".hidden").each(function(){
        $(this).removeClass('hidden');
    });
}

//select items by checked params in filter
function filterDivs(){
    $('html, body').animate({scrollTop:0}, 1500);
    $('.nothing_found').hide();
    var tp = '.tp'+$('#filter .tparam.radioChecked').find('input').attr('value');
    var thp = '.thp'+$('#filter .thparam.radioChecked').find('input').attr('value');
    var cp = '.cp'+$('#filter .cparam.radioChecked').find('input').attr('value');
    var yp = '.yp'+$('#filter .yparam.radioChecked').find('input').attr('value');
    if(tp=='.tp'||tp=='.tpundefined'){tp='';}
    if(thp=='.thp'||thp=='.thpundefined'){thp='';}
    if(cp=='.cp'||cp=='.cpundefined'){cp='';}
    if(yp=='.yp'||yp=='.ypundefined'){yp='';}
    $('.cat div.cat_item').stop().hide();
    $('.showNext').hide();
    clearHidden();
    var sites=$('.cat div'+tp+thp+cp+yp);
    if(sites.length!=0){
        $('.cat div.cat_item'+tp+thp+cp+yp).each(function(){
            $(this).addClass('hidden');
        });
        if(sites.length>count){
            $('.showNext').show();
            $('#lotSite').show();
            $('#lowSite').hide();
        }
        else{
            $('#lotSite').hide();
            $('#lowSite').show();
        }
        var elem=$('.cat div.cat_item'+tp+thp+cp+yp+':lt('+count+')');
        elem.each(function(){
            $(this).show(800);
            $(this).removeClass('hidden');
        });
    }else{
        $('.nothing_found').show();
        $('#lotSite').hide();
        $('#lowSite').show();
    }
    return false;
}

function sendBigOrder(form)
{
	
	var nameVal=$("#send_name").val();
	if(nameVal=="Ваше имя") $("#send_name").val("");
	
	var cityVal=$("#send_city").val();
	if(cityVal=="Ваш город") $("#send_city").val("");
    
    return true;
}

function loadBigOrder(form)
{  	
	if($("#send_name").val()=="") $("#send_name").val("Ваше имя");
	
	if($("#send_city").val()=="") $("#send_city").val("Ваш город");
    
    return true;
}

$(document).ready(function() {
	$('.sch_part').mouseover(function(){
		var src=$(this).find('img.sch_pic').eq(0).attr('src');
		var new_src=src.substring(0,19)+'_h.png';
		$(this).find('img.sch_pic').eq(0).attr('src',new_src);
	}).mouseout(function(){
		var src=$(this).find('img.sch_pic').eq(0).attr('src');
		var new_src=src.substring(0,19)+'.png';
		$(this).find('img.sch_pic').eq(0).attr('src',new_src);
	});
});

function sendWantTop(form)
{
    $(form).hide();
    $("#want_to_top_loader").show();

    $.ajax({
        url: $(form).attr('action'),
        type: "POST",
        data: $(form).serialize(),
        success: function(response) {

			try{
				var obj=JSON.parse(response);
				if(obj.success) window.location.replace("success.html");				
				else{
					$(form).parents('div.header_form_block').eq(0).html(response);
				}
			}
			catch(ex){
				$(form).parents('div.header_form_block').eq(0).html(response);	
			}
        },
        error: function(response) {
            alert("На сервере произошла ошибка при обработке Вашего запроса");
            $(form).show();
            $("#want_to_top_loader").hide();
        },
        complete:function () {
            try {}
            finally {					
					$(".header_form span.submit").css('display','none');
					$(".header_form .div_en input").css('display','block');
					$(".header_form #want_top_type_site").css('display','none');	
					$(".header_form input.sub_en").css('display','block');
            }
        }
    });

    return false;
}
function sendWantTopCourse(form)
{
    $(form).hide();
    $("#want_to_top_loader").show();

    $.ajax({
        url: $(form).attr('action'),
        type: "POST",
        data: $(form).serialize(),
        success: function(response) {

			try{
				var obj=JSON.parse(response);
				if(obj.success) window.location.replace("successful-registration.html");				
				else{
					$(form).parents('div.header_form_block').eq(0).html(response);
				}
			}
			catch(ex){
				$(form).parents('div.header_form_block').eq(0).html(response);	
			}
        },
        error: function(response) {
            alert("На сервере произошла ошибка при обработке Вашего запроса");
            $(form).show();
            $("#want_to_top_loader").hide();
        },
        complete:function () {
            try {}
            finally {					
					$(".header_form span.submit").css('display','none');
					$(".header_form .div_en input").css('display','block');
					$(".header_form #want_top_type_site").css('display','none');	
					$(".header_form input.sub_en").css('display','block');
            }
        }
    });

    return false;
}
function sendWaitForm(form)
{
    $(form).hide();
    $("#want_to_top_loader").show();

    $.ajax({
        url: $(form).attr('action'),
        type: "POST",
        data: $(form).serialize(),
        success: function(response) {

			try{
				var obj=JSON.parse(response);
				if(obj.success) window.location.replace("success.html");				
				else{
					$(form).parents('div#wait_form').eq(0).html(response);
				}
			}
			catch(ex){
				$(form).parents('div#wait_form').eq(0).html(response);	
			}
        },
        error: function(response) {
            alert("На сервере произошла ошибка при обработке Вашего запроса");
            $(form).show();
            $("#want_to_top_loader").hide();
        },
        complete:function () {
            try {}
            finally {					
					/*$(".header_form span.submit").css('display','none');
					$(".header_form .div_en input").css('display','block');
					$(".header_form #want_top_type_site").css('display','none');	
					$(".header_form input.sub_en").css('display','block');*/
            }
        }
    });

    return false;
}

function sendSame(form)
{
    $(form).hide();
    $("#want_to_top_loader").show();

    $.ajax({
        url: $(form).attr('action'),
        type: "POST",
        data: $(form).serialize(),
        success: function(response) {

			try{
				var obj=JSON.parse(response);
				if(obj.success) window.location.replace("success.html");				
				else{
					$(form).parents('div').eq(0).html(response);
				}
			}
			catch(ex){
				$(form).parents('div').eq(0).html(response);
			}
        },
        error: function(response) {
            alert("На сервере произошла ошибка при обработке Вашего запроса");
            $(form).show();
            $("#want_to_top_loader").hide();
        }
    });

    return false;
}

function sendProposal(form)
{
    $(form).hide();
    $("#prop_loader").show();

    $.ajax({
        url: $(form).attr('action'),
        type: "POST",
        data: $(form).serialize(),
        success: function(response) {

			try{
				var obj=JSON.parse(response);
				if(obj.success) window.location.replace("success.html");				
				else{
					$(form).parents('div.index_form_block').eq(0).html(response);
				}
			}
			catch(ex){
				$(form).parents('div.index_form_block').eq(0).html(response);
			}
        },
        error: function(response) {
            alert("На сервере произошла ошибка при обработке Вашего запроса");
            $(form).show();
            $("#prop_loader").hide();
        }
    });

    return false;
}

function sendDir(form)
{
    $(form).hide();
    $("#prop_loader").show();

    $.ajax({
        url: $(form).attr('action'),
        type: "POST",
        data: $(form).serialize(),
        success: function(response) {

			try{
				var obj=JSON.parse(response);
				if(obj.success) window.location.replace("success.html");				
				else{
					$(form).parents('div.bot_form').eq(0).html(response);
				}
			}
			catch(ex){
				$(form).parents('div.bot_form').eq(0).html(response);
			}
        },
        error: function(response) {
            alert("На сервере произошла ошибка при обработке Вашего запроса");
            $(form).show();
            $("#prop_loader").hide();
        }
    });

    return false;
}

function sendOrder(form)
{
    $(form).hide();
    $("#prop_loader").show();

    $.ajax({
        url: $(form).attr('action'),
        type: "POST",
        data: $(form).serialize(),
        success: function(response) {

			try{
				var obj=JSON.parse(response);
				if(obj.success) window.location.replace("success.html");				
				else{
					$(form).parents('div.bot_form').eq(0).html(response);	
					$('input[type="checkbox"]').wrap('<span class="niceCheck">');			
					jQuery(".niceCheck").each(
						function() {			 
							 changeCheckStart(jQuery(this));			 
					});
				}
			}
			catch(ex){
				$(form).parents('div.bot_form').eq(0).html(response);	
				$('input[type="checkbox"]').wrap('<span class="niceCheck">');			
				jQuery(".niceCheck").each(
					function() {			 
						 changeCheckStart(jQuery(this));			 
				});
			}
        },
        error: function(response) {
            alert("На сервере произошла ошибка при обработке Вашего запроса");		
            $(form).show();
            $("#prop_loader").hide();
        }
    });

    return false;
}
function sendFBMain(form)
{
    $(form).hide();
    $("#prop_loader").show();

    $.ajax({
        url: $(form).attr('action'),
        type: "POST",
        data: $(form).serialize(),
        success: function(response) {

			try{
				var obj=JSON.parse(response);
				if(obj.success) window.location.replace("success.html");				
				else{
					$(form).parents('div.form_block').eq(0).html(response);	
				}
			}
			catch(ex){
				$(form).parents('div.form_block').eq(0).html(response);	
			}
        },
        error: function(response) {
            alert("На сервере произошла ошибка при обработке Вашего запроса");		
            $(form).show();
            $("#prop_loader").hide();
        }
    });

    return false;
}

var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

/*!
 * Contained Sticky Scroll v1.1
 * http://blog.echoenduring.com/2010/11/15/freebie-contained-sticky-scroll-jquery-plugin/
 *
 * Copyright 2010, Matt Ward
*/
(function( $ ){

  $.fn.containedStickyScroll = function( options ) {
  
	var defaults = {  
		unstick : true,
		easing: 'linear',
		duration: 500,
		queue: false,
		closeChar: '^',
		closeTop: 0,
		closeRight: 0  
	}  
                  
	var options =  $.extend(defaults, options);
    var $getObject = $(this).selector;
    var toBot = jQuery(window).height() - jQuery($getObject).height() - 10;
	if(options.unstick == true){  
		this.css('position','absolute');
		this.css('top','0px');
	} 
	else{
		this.css('position','static');
	}
  	jQuery(window).scroll(function() {
        if(jQuery(window).scrollTop() > (jQuery($getObject).parent().offset().top)){
		if((jQuery($getObject).parent().height()) > (jQuery(window).scrollTop() - jQuery($getObject).parent().offset().top + toBot + jQuery($getObject).height())){
        	jQuery($getObject).animate({ top: (jQuery(window).scrollTop() - jQuery($getObject).parent().offset().top + toBot) + "px" }, 
            { queue: options.queue, easing: options.easing, duration: options.duration });
        }
		else{
			jQuery($getObject).css('margin-top',0);
		}
		}
        else if(jQuery(window).scrollTop() < (jQuery($getObject).parent().offset().top)){
        	jQuery($getObject).animate({ top: "0px" },
            { queue: options.queue, easing: options.easing, duration: options.duration });
			jQuery($getObject).css('margin-top',0);
        }
	});

  };
})( jQuery );

/************ expo parallax **************/
var expo_height = 0,
    screen_height = 0,
    expo_stop = 0,
    expo_start = 0,
    expo_k = 0;

$(document).ready(function(){
    if($('.expo').length){
        var img = new Image();
        img.src= $('.expo_logo').attr('src');
        img.loaded = false;
        img.onload = function(){
            console.log('2');
            expo_height = $('.expo').height();
            screen_height = $(window).height();
            expo_stop = $('.expo').offset().top;
            expo_start = expo_stop - screen_height;
            expo_k = expo_height/screen_height;
        }
    }
});
window.onscroll = function(){
    if($('.expo').length){
        var top = $(window).scrollTop();
        if((top >= expo_start) && (top <= expo_stop)){
            var t = (top - expo_start)*expo_k;
            $('.expo .bg').css('bottom','-'+ t + 'px');
        }
    }
};

$(document).ready(function(){
    $(".phone_mask").on("blur", function() {
        if($(this).val()=='+971')
        {
            $(this).val('');
        }
    });

    $(".phone_mask").on("change keyup input click", function() {
        var value = $(this).val();
        if (value.match(/[^0-9\+]/g)) {
            value = value.replace(/[^0-9\+]/g, '');
        }
        $(this).val(value);
    });

    $(".phone_mask").on("focus", function() {
        if($(this).val() == '' )
        {
            $(this).val('+971');
        }
    });
})