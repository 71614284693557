$(function(){ 
	$(".header_form #want_top_type_site").val('http://');	
	$(".header_form .div_en input").css('display','none');
	$(".header_form #want_top_type_site").css('display','block');
	$(".header_form input.sub_en").css('display','none');
	if($(window).width()>=640) {$(".header_form span.submit").css('display','inline-block');}
	
	$(".header_form span.submit").click(function(){
		if($(".header_form #want_top_type_site").val()!=""&&$(".header_form #want_top_type_site").val()!="http://"){
			$(".header_form .div_en input").css('display','block');
			$(".header_form #want_top_type_site").css('display','none');	
			$(".header_form input.sub_en").css('display','block');
			$(".header_form span.submit").css('display','none');
		}
		else{			
			$(".header_form #want_top_type_site").css('border-color','red');	
			$(".header_form #want_top_type_site").parent('.div_en').append('<div class="errors_ind"><ul><li>The field must be filled</li></ul></div>');
		}
		return false;
	});
	$(".header_form #want_top_type_site").focus(function(){
		$(".header_form #want_top_type_site").css('border-color','#7d8492');	
		$(".header_form #want_top_type_site").parent('.div_en').find('.errors_ind').hide(0);
	});
});
	
$(document).ready(function(){
    $("#slider").responsiveSlides({
        auto: false,
        pager: true,
        nav: true,
        pauseControls: true,
        pause: true,
		timeout: 5000,
        speed: 1100
    });

    $('.owl-carousel').owlCarousel({
		loop:true,
		margin:0,
		nav:false,
        rtl:  ( $('html').attr('dir') === "rtl" )  ,
		dots:false,
		autoplay:true,
		autoplayHoverPause:true,
		items:4,
		smartSpeed:500,
		slideBy:4,
		autoplayTimeout: 2000
	});
		$(".menu_icon").click(function(){
			$('.top_menu').toggle();
			return false;
		});
		
		$('input[type="checkbox"]').each(function(){
			if(!$(this).hasClass("item")) $(this).wrap('<span class="niceCheck">');
		});
	
		if(document.getElementById('parallax')){
			$(function(){
				$.stellar({
					horizontalScrolling: false,
					verticalOffset: 10
				});
			});
		}
	});
	function checkPositionFloat(mobile){
		if(mobile) return false;
		else{
			dirRTL = ( $('html').attr('dir') === "rtl" );
			if($(window).width()>1010&&document.getElementById('item_text')){
				var offset=$(document).scrollTop();
				var divOffset=$('.item_text').offset().top-100;
				var top=$('.top_fixed').outerHeight()+40;
				var leftPart=$('#content_main').outerHeight()+$('#header_main').outerHeight()-$('.right-portfolio').outerHeight()-100;
				if($('.imgs').outerHeight()>$('.right-portfolio').outerHeight()&&offset>=divOffset){
					if(offset<=leftPart){
						if(dirRTL){
                            $('.right-portfolio').css({'position':'fixed','top':top, 'margin-right':'0%', 'margin-left':'8%'});
						} else {
                            $('.right-portfolio').css({'position':'fixed','top':top, 'margin-right':'8%', 'margin-left':'0%'});
						}

						$('.item_text').css('position','static');
					}else{
						$('.right-portfolio').css({'position':'absolute','top':'auto', 'bottom':'10px', 'margin-right':'18%', 'margin-left':'0%'});
                        if(dirRTL){
                            $('.right-portfolio').css({'position':'fixed','top':top, 'margin-right':'0%', 'margin-left':'8%'});
                        } else {
                            $('.right-portfolio').css({'position':'fixed','top':top, 'margin-right':'8%', 'margin-left':'0%'});
                        }
						$('.item_text').css('position','static');
						$('.item').css('position','relative');
					}
				}
				else{
					$('.right-portfolio').css({'position':'static','top':0, 'margin-right':'0%', 'margin-left':'0%'});
					$('.item_text').css('position','relative');
				}
			}
			else{
					$('.right-portfolio').css({'position':'static','top':0, 'margin-right':'0%', 'margin-left':'0%'});
					$('.item_text').css('position','relative');
			}
			return false;
		}
	}
	var countStop=false;
$(window).scroll( function(){	
	if(!countStop&&document.getElementById('numbers')&&window.pageYOffset>$(".numbers").offset().top-($(window).height()-100)){	
	$('#animate_num1').stop().animateNumber(
	{
		number: 609,
		numberStep: function(now, tween) {
		  var floored_number = Math.floor(now),
			  target = $(tween.elem);

		  target.text(floored_number);
		}
	  },
	  3000
	);
	$('#animate_num2').stop().animateNumber(
	{
		number: 16724,
		numberStep: function(now, tween) {
		  var floored_number = Math.floor(now),
			  target = $(tween.elem);

		  target.text(floored_number);
		}
	  },
	  3000
	);
	$('#animate_num3').stop().animateNumber(
	{
		number: 68704,
		numberStep: function(now, tween) {
		  var floored_number = Math.floor(now),
			  target = $(tween.elem);

		  target.text(floored_number);
		}
	  },
	  3000
	);
	$('#animate_num4').stop().animateNumber(
	{
		number: 8,
		numberStep: function(now, tween) {
		  var floored_number = Math.floor(now),
			  target = $(tween.elem);

		  target.text(floored_number);
		}
	  },
	  3000
	);
	
	countStop=true;
}
	
		// add class to right portfolio if not in mobile
		if( navigator.userAgent.match(/Android/i)
		 || navigator.userAgent.match(/webOS/i)
		 || navigator.userAgent.match(/iPhone/i)
		 || navigator.userAgent.match(/iPod/i)
		 || navigator.userAgent.match(/BlackBerry/i)
		 ){
		 var mobile=true;
		}else{
		 var mobile=false;
		}
	checkPositionFloat(mobile);
});
$(window).bind('resize load', function(){
	var w=$(window).width();
	if(w<1010){
		$('.rslides_tabs').width($(window).width()-10);
		$('.rslides_tabs').css('margin-left', -$(window).width()/2);
		$('.caption').width($(window).width()-10);
		$('.caption').css('margin-left', -$(window).width()/2);
	}
	else{
		$('.rslides_tabs').width(960);
		$('.rslides_tabs').css('margin-left', '-480px');
		$('.caption').width(960);
		$('.caption').css('margin-left', '-480px');
	}
	if(w>933){	
		$('.top_menu').css('display','block');
	}
	else{
		// $('.top_menu').css('display','none');
	}
	
		if(w>893){
			$('.expo_big').attr('src','images/expo.jpg');
		}else{
			$('.expo_big').attr('src','images/expo_min.jpg');
		}
		if(document.getElementById("need_to_open_form")){
			if (w >= 640){
				$(".header_form #want_top_type_site").val('http://');	
				$(".header_form .div_en input").css('display','none');
				$(".header_form input.sub_en").css('display','none');
				$(".header_form #want_top_type_site").css('display','block');
				$(".header_form span.submit").css('display','inline-block');
				$(".header_form_opened #uniq_offer_type_site").val('http://');	
				$(".header_form_opened .div_en input").css('display','none');
				$(".header_form_opened input.sub_en").css('display','none');
				$(".header_form_opened #uniq_offer_type_site").css('display','block');
				$(".header_form_opened span.submit").css('display','inline-block');
			}
			else{
				$(".header_form #want_top_type_site").val('http://');	
				$(".header_form .div_en input").css('display','block');
				$(".header_form input.sub_en").css('display','block');
				$(".header_form #want_top_type_site").css('display','block');
				$(".header_form span.submit").css('display','none');
				$(".header_form_opened #uniq_offer_type_site").val('http://');	
				$(".header_form_opened .div_en input").css('display','block');
				$(".header_form_opened input.sub_en").css('display','block');
				$(".header_form_opened #uniq_offer_type_site").css('display','block');
				$(".header_form_opened span.submit").css('display','none');
			}
		}
	
		$("li.parent > a").click(function(){
			console.log('click');
			$(this).parent().toggleClass('open');
			var w=$(window).width();
			if(w<=550){
				$(this).parent().find('ul').toggle();
				return false;
			}
			else{
				return true;
			}
		});
		$("li.parent").mouseover(function(){
            // console.log('mouseover');

			var w=$(window).width();
			if(w>550){
                $(this).addClass('open');
				$(this).find('ul').show();
			}
			return false;
		}).mouseout(function(){
            // console.log('mouseout');

			var w=$(window).width();
			if(w>550){
                $(this).removeClass('open');
				$(this).find('ul').hide();
				return false;
			}
			return false;
		});
	return false;
});
		
(function($){  
      
    $(function(){  
        var e = $(".scrollTop");  
        var speed = 500;  
      
        e.click(function(){  
            $("html:not(:animated),body:not(:animated)").animate({ scrollTop: 0}, 500 );  
            return false; 
        });  
		
        function show_scrollTop(){  
            ( $(window).scrollTop()>50 ) ? e.fadeIn(600) : e.hide();  
        }  
        $(window).scroll( function(){show_scrollTop()} ); show_scrollTop();  
	});  
      
})(jQuery);

		(function($){
			$(window).load(function(){
				$(".tabs-list").mCustomScrollbar({
					axis:"x",
					theme:"light-3",
					advanced:{autoExpandHorizontalScroll:true},
					scrollbarPosition:"outside",
					autoDraggerLength: true,
					mouseWheel:{enable: false},
				});
			});
			$(document).ready(function() {  
			  $('div.tabs-cont').each(function() {
				$(this).find('.tabs-thumb').each(function(i) {
				  $(this).click(function() {
					$(this).addClass('active-thumb').siblings().removeClass('active-thumb');
					var p = $(this).parents('div.cont-wrap');
					p.find('div.tab-item').hide();
					p.find('div.tab-item:eq(' + i + ')').show();
				  });
				});
			  });
			})
		})(jQuery);