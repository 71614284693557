$(function(){
    if (typeof resultsBaseUrl !== "undefined") {
        $(".load-results").each(function () {
            var listId = $(this).attr("data-list-id");
            var element = $(this);
            $.post(resultsBaseUrl, {'listId': listId}, function(data){element.html(data)}).fail(function(data, textStatus, errorThrown){
                console.log("Unable to load results, error has been broken: " + textStatus);
            });
        });
    } else {
        console.log("Unable to load results: base url is not defined");
    }
});